export default [
    {
        path: "/orders-tabs",
        name: "الطلبيات",
        component: () => import("@/views/orders/tabs.vue"),
        meta: {
            single: "طلبية",
            endPoint: "Orders",
            breadcrumbs: [{ text: "إدارة الطلبيات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "orders"],
        },
    },
    {
        path: "/orders",
        name: "كل الطلبيات",
        component: () => import("@/views/orders/orders.vue"),
        meta: {
            single: "طلبية",
            endPoint: "Orders",
            breadcrumbs: [{ text: "كل الطلبيات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "orders"],
        },
    },
    {
        path: "/orderReturns",
        name: "الطللبيات الراجعة",
        component: () => import("@/views/orders/orderReturns.vue"),
        meta: {
            single: "طلبية",
            endPoint: "Orders",
            breadcrumbs: [{ text: "إدارة الطلبيات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "orders"],
        },
    },

    {
        path: "/orderDetails/:id",
        name: "تفاصيل الطلبية",
        props: true,
        component: () => import("@/views/orders/details.vue"),
        meta: {
            single: "طلبية",
            endPoint: "Orders",
            breadcrumbs: [{ text: "إدارة الطلبيات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "orders"],
        },
    },
];
