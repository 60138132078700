export default [
    {
        path: "/categories",
        name: "الاصناف",
        component: () => import("@/views/warehouse/categories/categories.vue"),
        meta: {
            single: "صنف",
            endPoint: "Categories",
            breadcrumbs: [{ text: "إدارة الاصناف", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "category"],
        },
    },
];
