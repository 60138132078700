import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function (value) {
    if (value) {
        return value.substr(0, 10);
    }
});

Vue.filter("timeSince", function (value) {
    if (value) {
        var data = new Date(value);

        var seconds = Math.floor((new Date() - data) / 1000);
        const years = Math.floor(seconds / 31536000);
        const months = Math.floor(seconds / 2592000);
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor(seconds / 60);

        if (years > 0) {
            if (years == 1) return "منذ سنة";
            if (years == 2) return "منذ سنتين";
            if (years >= 3 && years <= 10) return "منذ (years) سنوات";
            else return `منذ ${years} سنة`;
        } else if (months > 0) {
            if (months == 1) return "منذ شهر";
            if (months == 2) return "منذ شهرين";
            if (months >= 3 && months <= 10) return `منذ ${months} شهور`;
            else return `منذ ${months} شهراً`;
        } else if (days > 0) {
            if (days == 1) return "منذ يوم";
            if (days == 2) return "منذ يومين";
            if (days >= 3 && days <= 10) return `منذ ${days} أيام`;
            else return `منذ ${days} يوماً`;
        } else if (hours > 0) {
            if (hours == 1) return "منذ ساعة";
            if (hours == 2) return "منذ ساعتين";
            if (hours >= 3 && hours <= 10) return `منذ ${hours} ساعات`;
            else return `منذ ${hours} ساعة `;
        } else if (minutes > 0) {
            if (minutes == 1) return "منذ دقيقة";
            if (minutes == 2) return "منذ دقيقتين";
            if (minutes >= 3 && minutes <= 10) return `منذ ${minutes} دقائق`;
            else return `منذ ${minutes} دقيقة`;
        } else if (seconds > 0) {
            if (seconds == 1) return "منذ ثانية";
            if (seconds == 2) return "منذ ثانيتين";
            if (seconds >= 3 && seconds <= 10) return `منذ ${seconds} ثوان`;
            else return `منذ ${seconds} ثانية`;
        } else {
            return "الآن";
        }
    }
});

Vue.filter("formatDateAgo", function (value) {
    if (value) {
        return moment(String(value)).fromNow(); // 4 years
    }
});

Vue.filter("formatFullDate", function (value) {
    if (value) {
        return moment(String(value)).format("LLLL"); // moment().format('MMMM Do YYYY, h:mm:ss a');
    }
});

Vue.filter("uperCase", function (v) {
    return v.toUpperCase();
});

Vue.filter("phone", function (phone) {
    return phone
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, "($1) $2-$3-$4");
});

var numeral = require("numeral");
Vue.filter("formatCurrency", function (value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
