import firebase from "firebase/app";
import "firebase/firebase-messaging";
var messaging;
var firebaseConfig = {
    apiKey: "AIzaSyCw7RrgnwTHbpZ2qiLTWR2s7zb8ewx4nTo",
    authDomain: "shams-algharee.firebaseapp.com",
    projectId: "shams-algharee",
    storageBucket: "shams-algharee.appspot.com",
    messagingSenderId: "311599702371",
    appId: "1:311599702371:web:b09ee4d78f130e16d07356",
};
firebase.initializeApp(firebaseConfig);
try {
    messaging = firebase.messaging();
} catch (e) {
    messaging = e;
}
export default messaging;
