<template>
    <div class="area">
        <v-row justify="center" align="center" style="height: 100%">
            <v-col class="text-center">
                <v-card width="490" flat class="border-card pa-6 mx-auto">
                    <v-card flat class="d-flex justify-center">
                        <div>
                            <v-img src="@/assets/img/logo1.svg" class="mx-auto" width="100" />
                            <h2 class="text-center mt-3 app-name-text">M A X</h2>
                        </div>
                    </v-card>

                    <v-card-title>
                        <h4>تسجيل دخول</h4>
                    </v-card-title>

                    <v-card-text
                        class="mt-n3 text-right"
                    >مرحبًا بك في نظام ماكس للإدارة مطبة شمس الغري</v-card-text>

                    <v-form @submit.prevent="submit" ref="form" v-model="valid" lazy-validation>
                        <v-card-actions>
                            <v-text-field
                                v-model="user.userNameOrPhoneNumber"
                                placeholder="اسم المستخدم"
                                outlined
                                filled
                                color="info"
                                :rules="[required()]"
                            ></v-text-field>
                        </v-card-actions>
                        <v-card-actions class="mt-n4">
                            <v-text-field
                                v-model="user.password"
                                placeholder="كلمة المرور"
                                outlined
                                filled
                                color="info"
                                :rules="[required()]"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                        </v-card-actions>
                        <v-card-actions>
                            <v-btn
                                :loading="loading"
                                type="submit"
                                block
                                large
                                class="btn mb-6 secondary--text"
                                color="primary"
                            >تسجيل الدخول</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { v4 } from "uuid";
import validations from "@/utils/validations";

export default {
    name: "Login",

    components: {
        // loginSupport: () => import("../../assets/animation/loginSupport.vue"),
    },
    data() {
        return {
            user: {},
            loading: false,
            valid: true,
            showPassword: false,
            ...validations,
        };
    },

    created() {
        this.user = {
            userNameOrPhoneNumber: "",
            password: "",
            idToken: "aa",
            clientId: this.generateUUID(),
            clientType: "web",
        };
    },

    methods: {
        async submit() {
            if (this.user.userNameOrPhoneNumber && this.user.password) {
                this.loading = true;
                // this.$refs.form.validate()
                // this.user.phoneNumber = this.user.phoneNumber.replace(/\s/g, '')
                await this.$http
                    .post("auth/login", this.user)
                    .then((res) => {
                        localStorage.setItem(
                            "accessToken",
                            res.data.result.accessToken
                        );
                        localStorage.setItem(
                            "userData",
                            JSON.stringify(res.data.result)
                        );
                        this.$store.dispatch("auth/login");
                        this.$store.dispatch("toastification/setToast", {
                            message: `تم تسجيل الدخول بنجاح`,
                            type: "success",
                        });
                        this.$router.push("/");
                    })
                    .catch((err) => {
                        this.$store.dispatch("toastification/setToast", {
                            message: `${err.data.message}`,
                            type: "error",
                        });
                        console.log(" error ", err);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading = false;
                    });

                // try {
                //     // if (res.data.result.userType == "operation" || res.data.result.userType == "admin") {
                //     await localStorage.setItem(
                //         "accessToken",
                //         res.data.result.accessToken
                //     );
                //     await localStorage.setItem(
                //         "userData",
                //         JSON.stringify(res.data.result)
                //     );
                //     await this.$store.dispatch("auth/login");
                //     this.$store.dispatch("toastification/setToast", {
                //         message: `تم تسجيل الدخول بنجاح`,
                //         type: "success",
                //     });

                //     await this.$router.push("/");
                // } catch (err) {
                //     this.$store.dispatch("toastification/setToast", {
                //         message: `${err.data.message}`,
                //         type: "error",
                //     });
                //     console.log(" error ", err);
                // } finally {
                //     this.loading = false;
                //     console.log(" finally ");
                // }
            } else {
                this.$store.dispatch("toastification/setToast", {
                    message: `الرجاء ادخل اسم المستخدم او كلمة المرور`,
                    type: "warning",
                });
            }
        },

        generateUUID() {
            return v4();
        },
    },
};
</script>

<style lang="scss">
.area {
    width: 100%;
    height: 90vh;
}
</style>
