export default [
    {
        path: "/news",
        name: "الاخبار والمناسبات",
        component: () => import("@/views/news/news.vue"),
        meta: {
            single: "خبر او مناسبة",
            endPoint: "News",
            breadcrumbs: [
                { text: "إدارة الاخبار والمناسبات", disabled: false },
            ],
            requireAuth: true,
            auth: ["administrator", "news"],
        },
    },
    {
        path: "/news-detials",
        name: "newsDetails",
        component: () => import("@/views/news/newsDetails.vue"),
        meta: {
            endPoint: "News",
            breadcrumbs: [{ text: "تفاصيل", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "news"],
            props: true,
        },
    },
];
