export const drawer = {
    namespaced: true,
    state: {
        mini_drawer: true,
    },

    mutations: {
        // mini_drawer
        TOGGLE_MINI_DRAWER(state) {
            state.mini_drawer = !state.mini_drawer;
        },
    },

    actions: {
        // mini_drawer
        toggleMiniDrawer({ commit }) {
            commit("TOGGLE_MINI_DRAWER");
        },
    },
};
