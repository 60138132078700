export default [
    {
        path: "/clients",
        name: "إدارة الزبائن",
        component: () => import("@/views/clients/clients.vue"),
        meta: {
            breadcrumbs: [{ text: "إدارة الزبائن", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "client"],
        },
    },
    {
        path: "/all-clients",
        name: "الزبائن",
        component: () => import("@/views/clients/allClients.vue"),
        meta: {
            single: "زبون",
            endPoint: "Clients",
            breadcrumbs: [{ text: "إدارة الزبائن", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "client"],
        },
    },
    {
        path: "/fav-clients",
        name: " الزبائن المميزين",
        component: () => import("@/views/clients/favClient.vue"),
        meta: {
            single: "زبون مميز",
            endPoint: "FavClients",
            breadcrumbs: [{ text: "إدارة الزبائن", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "client"],
        },
    },
    {
        path: "/block-clients",
        name: " الزبائن المحظورين",
        component: () => import("@/views/clients/blkClient.vue"),
        meta: {
            single: " محظور",
            endPoint: "BlkClient",
            breadcrumbs: [{ text: "إدارة الزبائن", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "client"],
        },
    },
    {
        path: "/new-clients",
        name: " الزبائن الجدد",
        component: () => import("@/views/clients/newClient.vue"),
        meta: {
            single: "زبون جديد",
            endPoint: "NewClient",
            breadcrumbs: [{ text: "إدارة الزبائن", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "client"],
        },
    },
    {
        path: "/dialog",
        name: "التسجسل اليدوي",
        component: () => import("@/views/clients/dialog.vue"),
        meta: {
            single: "زبون جديد",
            endPoint: "Clients",
            breadcrumbs: [{ text: "إدارة الزبائن", disabled: true }],
            requireAuth: true,
            auth: ["administrator", "client"],
        },
    },
];
