export default [
    {
        path: "/404",
        name: "pageNotFound",
        component: () => import("../views/404.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/forbidden",
        name: "forbidden",
        component: () => import("../views/forbidden.vue"),
        meta: {
            hideNavigation: true,
        },
    },

    {
        path: "/",
        name: "الرئيسية",
        component: () => import("../views/home/home.vue"),
        meta: {
            single: "الرئيسية",
            endPoint: "Dashboards",
            breadcrumbs: [{ text: "الرئيسية", disabled: false }],
            requireAuth: true,
        },
    },

    {
        path: "/pusherTest",
        name: "pusherTest",
        component: () => import("../views/pusherTest.vue"),
        meta: {
            hideDrawer: false,
        },
    },

    {
        path: "/myProfile",
        name: "الملف الشخصي",
        component: () => import("@/components/user/userDetails.vue"),
        meta: {
            single: "الرئيسية",
            endPoint: "Auth",
            breadcrumbs: [{ text: "الرئيسية", disabled: false }],
            requireAuth: true,
        },
    },

    {
        path: "/dashboard",
        name: "home",
        component: () => import("../views/dashboard/dashboard.vue"),
        meta: {
            hideDrawer: false,
            auth: ["administrator", "dashboard"],
        },
    },
    {
        path: "/login",
        name: "تسجيل الدخول",
        component: require("../views/auth/login.vue").default,
        meta: {
            hideNavigation: true,
        },
    },

    {
        path: "*",
        meta: {
            requireAuth: true,
        },
        redirect: "/404",
    },

    {
        path: "/users",
        name: "المستخدمين",
        component: () => import("../views/users/users.vue"),
        meta: {
            single: "مستخدم",
            endPoint: "Users",
            breadcrumbs: [{ text: "إدارة المستخدمين", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "users"],
        },
    },

    {
        path: "/authGroups",
        name: "الصلاحيات",
        component: () => import("../views/users/authGroups/authGroups.vue"),
        meta: {
            single: "صلاحية",
            endPoint: "AuthGroups",
            breadcrumbs: [{ text: "إدارة الصلاحيات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "users"],
        },
    },

    {
        path: "/settings",
        name: "الإعدادات",
        component: () => import("../views/settings/settings.vue"),
        meta: {
            single: "الإعدادات",
            endPoint: "settings",
            breadcrumbs: [{ text: "الإعدادات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "settings"],
        },
    },

    {
        path: "/clientTabs",
        name: "التبويبات",
        component: () => import("../views/settings/clientTabs/clientTabs.vue"),
        meta: {
            single: "تبويب",
            endPoint: "ClientTabs",
            breadcrumbs: [{ text: "التبويبات", disabled: false }],
            requireAuth: true,
            auth: ["administrator", "settings"],
        },
    },
];
